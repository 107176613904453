import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { motion } from "framer-motion"

// import BackgroundImage from "gatsby-background-image"
import Footer from "../components/footer"
import "../styles/components/layout.scss"
import styled from "styled-components"
import NavBar from "./navbar"
import GlobalStyles from "../components/Global"

const LayoutStyled = styled.div`
  /* background-color: var(--background-color1); */
  min-height: 100vh;
  position: relative;
  /* font-family: "Lato"; */
`

class Layout extends React.Component {
  render() {
    const { children } = this.props

    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <StaticQuery
        query={graphql`
          query {
            background: file(relativePath: { eq: "IMG_1462.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <LayoutStyled>
              <NavBar />
              <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                {children}
              </motion.main>
              <GlobalStyles />
            </LayoutStyled>
          )
        }}
      />
    )
  }
}

export default Layout
