import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    ${"" /* --color1: #35cb70; */}
    --color1: rgb(56, 211, 159);
    --subtitle-size: 25px;
    --background-color1: #070707;
    font-family: "Lato", sans-serif;
  }
  html,
  body {
    background-color: var(--background-color1);
  }

  p {
  font-size: 35px;
  font-weight: 300;
}

  h1 {
    font-size: 70px;
    line-height: 1em;
    margin-bottom: 25px;
  }
  h2 {

    font-size: 50px;
  }

`

export default GlobalStyles
