import React from "react"
import { Link } from "@reach/router"
// import { HamburgerIcon } from "./svgs"
import styled from "styled-components"

import { Below } from "./CustomComponents"

const NavBarStyled = styled.div`
  display: flex
  justify-content: end;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  padding: 10px 0 10px 0;
  min-height: 30px;
  z-index: 9;
  ${Below.med`
  /* sit above small logo on mobile */
    z-index:102;
    justify-content: center;
  `}
`

const NavBarLinks = styled.nav`
  display: flex;
  font-size: 30px;
  font-weight: 300;
  justify-content: end;
  column-gap: 10px;
  /* auto-fit and auto-fill not working properly */
  grid-template-columns: 1fr repeat(4, minmax(50px, auto));
  margin-right: 30px;

  ${Below.med`
    font-size: 20px;
    text-align: center;
    grid-template-columns: repeat(4, minmax(50px,auto));
    justify-content: center;
    margin-right:0;
    
  `}
`

const NavBarLink = styled(Link)`
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 15px 7px 15px;
  border-radius: 15px;

  :hover {
    color: var(--color1);
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const NavSpacer = styled.div`
  ${Below.med`
    display:none;
  `}
`

const NavBar = () => {
  const pages = {
    home: ["Home", "/"],
    services: ["Services", "/services"],
    contact: ["Contact", "/contact"],
  }

  const pageKeyArray = Object.keys(pages)

  return (
    <NavBarStyled>
      {/* <div class="navbar__item">Hi</div> */}

      <NavBarLinks>
        <NavSpacer className="navbar__spacer"></NavSpacer>
        {pageKeyArray.map((key, index) => (
          <NavBarLink
            className="navbar__link"
            activeClassName="navbar__activeLink"
            to={`${pages[key][1]}`}
            key={index}
          >{`${pages[key][0]}`}</NavBarLink>
        ))}
        {/* <HamburgerIcon></HamburgerIcon> */}
      </NavBarLinks>
    </NavBarStyled>
  )
}

export default NavBar
