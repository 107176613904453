import React from "react"
import styled, { css } from "styled-components"

const Info = styled.div`
  display: grid;
  max-width: 80vw;
  padding: 55px 0;
`

export const InfoContainer = ({ children }) => {
  return <Info>{children}</Info>
}

export const TextEmph = styled.span`
  color: var(--color1);
  font-weight: 400;
`
export const ButtonStyled = styled.button`
  border: 2px solid var(--color1);
  border-radius: 30px;
  color: var(--color1);
  background-color: var(--background-color1);
  font-size: 25px;
  padding: 10px 20px;
  width: fit-content;
  justify-self: end;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: var(--color1);
    color: white;
    cursor: pointer;
    transform: scale(1.2);
    border: none;
  }
`
// media query set up

const size = {
  small: 400,
  med: 960,
  large: 140,
}

const reducer = (accumulator, label) => {
  // get the value of the accumulator in the label position
  // this is going to be an object
  accumulator[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)}
    }
  `
  // return the accumulator after each loop
  return accumulator
}

// media query
// e.g. "above the tablet"
// Array is going to be [small, medium, large]
// empty object as the default value -to initialize our reduce as an object
export const Below = Object.keys(size).reduce(reducer, {})
